import { version } from './version';

export const environment = {
  production: true,
  local: false,
  version,
  origin: 'https://cds-apps-harvester-dev.hi5.platform.navify.com',
  firebase: {
    apiKey: 'AIzaSyAFi5SqFWHuSSGO5cyrhrLKdgLpMsa1Jmk',
    authDomain: 'harvester-dev-env.firebaseapp.com',
    databaseURL: 'https://harvester-dev-env.firebaseio.com',
    projectId: 'harvester-dev-env',
    storageBucket: 'harvester-dev-env.appspot.com',
    messagingSenderId: '455579080162',
    appId: '1:455579080162:web:1cea35ee1d1812fd',
    measurementId: 'G-J4WD6YBV1H',
    region: 'europe-west1'
  },
  projectId: 'harvester-dev-env',
  stackdriver: {
    errorsReporter: {
      key: 'AIzaSyDu7di5NxWPJJ4fcB8w285REtb9ooA0xSA',
      disabled: false,
      bypass: true
    }
  },
  redirects: {
    user: 'guidelines',
    anonymous: 'auth'
  },
  okta: {
    orgUrl: 'https://rocherapid-test.okta.com',
    clientId: '0oa7tncm6vHBokydH297',
    customTokenUrl:
      'https://europe-west1-harvester-dev-env.cloudfunctions.net/okta-customToken',
    issuerPath: '/oauth2/default'
  },
  auth: {
    oktaIsEnabled: true
  },
  gdlApps: {
    NTB: {
      url: 'https://su-appsdev.appsdev-tumorboard.hi5.platform.navify.com',
      api: {
        guidelines: '/cds-gdl-guidelines-service/api/v2/guidelines'
      },
      tenant: 'su-appsdev',
      exportOptions: {
        version: '3',
        addRules: false
      }
    },
    NCH: {
      url: 'https://su-appsdev.appsdev-nch.hi5.clinicalhub.platform.navify.com',
      api: {
        guidelines: '/guidelines-service-noh/api/v3/guidelines'
      },
      tenant: 'su-appsdev',
      exportOptions: {
        version: '3',
        addRules: false
      }
    }
  },
  autotraversal: {
    version: '2.1'
  }
};
