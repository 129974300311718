import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DocumentChange, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';

export function createDocumentChangeActionsList<T>(): ({
  docs
}: any) => DocumentChange<T>[] {
  return ({ docs }) => docs.map(createDocumentChangeAction());
}

export function createDocumentChangeAction<T>(): (
  doc: any
) => DocumentChange<T> {
  return (doc) => (doc ? ({ type: 'added', payload: { doc } } as any) : null);
}

export function changeActionsToNgrxActions<T, C = T>(
  actionCreators: {
    added?: (data: C[]) => Action;
    modified?: (updates: Update<C>[]) => Action;
    removed?: (ids: string[]) => Action;
    loaded?: () => Action;
  } = {},
  dataObjectFactory?: (data: T) => C,
  selectId?: (data: T) => string
): (changes: DocumentChange<T>[]) => Observable<Action> {
  const dataObjectMapper = (data2: T): C => {
    if (dataObjectFactory) {
      return dataObjectFactory(data2);
    }
    return data2 as unknown as C;
  };

  return (changes) => {
    const { added, removed, modified } = changes.reduce(
      (all, change) => {
        const doc = change.doc as QueryDocumentSnapshot<T> & {
          id: string;
        };

        switch (change.type) {
          case 'added': {
            all.added.push(dataObjectMapper(doc.data()));
            break;
          }

          case 'modified': {
            const data = doc.data();
            const id = selectId ? selectId(data) : (data as any).id || doc.id;
            all.modified.push({
              id,
              changes: dataObjectMapper(data)
            });
            break;
          }

          case 'removed': {
            all.removed.push(doc.id);
            break;
          }
        }

        return all;
      },
      { added: [], modified: [], removed: [] }
    );

    const actions = [];
    let markAsLoaded = false;

    if (actionCreators.added && added.length) {
      markAsLoaded = true;
      actions.push(actionCreators.added(added));
    }

    if (actionCreators.modified && modified.length) {
      actions.push(actionCreators.modified(modified));
    }

    if (actionCreators.removed && removed.length) {
      actions.push(actionCreators.removed(removed));
    }

    if (!added.length && !modified.length && !removed.length) {
      markAsLoaded = true;
    }

    if (actionCreators.loaded && markAsLoaded) {
      actions.push(actionCreators.loaded());
    }

    return from(actions);
  };
}
