import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface InfoMessageOptions {
  actionLabel?: string;
  duration?: number;
}

@Injectable()
export class MessengerService {
  constructor(private readonly snackBar: MatSnackBar) {}

  error(message: string) {
    this.snackBar.open(message, 'Close', { duration: 15000 });
  }

  info(message: string, options: InfoMessageOptions = {}) {
    const duration = options.duration || 3000;
    const actionLabel = options.actionLabel;

    return this.snackBar.open(message, actionLabel, { duration });
  }
}
