import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { AuthService } from '@gdl/auth/browser/core';
import { tap } from 'rxjs/operators';
import { ErrorReportingService } from '@gdl/shared/browser/error-reporting';
import { AppbarService } from '@gdl/shared/browser/shell/appbar';
import { NotificationsFacade } from '@gdl/notifications/browser/data';

@Injectable()
export class AppEffects {
  authChanges$ = createEffect(
    () =>
      this.authService.user$.pipe(
        tap((user) => {
          if (user) {
            const { id, photoURL, providerId } = user;
            this.notificationsFacade.enableSync();
            this.errorReporter.setUser(id);
            this.appbar.setUserPhotoURL(photoURL);
            this.appbar.setProviderId(providerId);
            this.appbar.show();
          } else {
            this.errorReporter.setUser();
            this.notificationsFacade.disableSync();
            this.appbar.hide();
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly notificationsFacade: NotificationsFacade,
    private readonly appbar: AppbarService,
    private readonly authService: AuthService,
    private readonly errorReporter: ErrorReportingService
  ) {}
}
