import { Routes } from '@angular/router';
import { AuthAdminGuard, AuthUserGuard } from '@gdl/auth/browser/core';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'guidelines'
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@gdl/auth/browser/shell').then((m) => m.AuthShellModule)
  },
  {
    path: 'guidelines',
    loadChildren: () =>
      import('@gdl/guidelines/shell').then((m) => m.GuidelinesShellModule),
    canActivate: [AuthUserGuard],
    canActivateChild: [AuthUserGuard]
  },
  {
    path: 'support',
    loadChildren: () =>
      import('@gdl/support/browser/shell').then((m) => m.SupportShellModule),
    canActivate: [AuthAdminGuard],
    canActivateChild: [AuthAdminGuard]
  },
  {
    outlet: 'external',
    path: 'content-editor',
    loadChildren: () =>
      import('@gdl/harvester/browser/content-editor').then(
        (m) => m.HarvesterContentEditorModule
      )
  },
  {
    outlet: 'external',
    path: 'rules-editor',
    loadChildren: () =>
      import('@gdl/harvester/browser/rules-editor').then(
        (m) => m.HarvesterRulesEditorModule
      )
  },
  {
    path: '**',
    redirectTo: 'guidelines'
  }
];
