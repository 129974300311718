import { NgModule } from '@angular/core';
import {
  provideAuth,
  initializeAuth,
  browserPopupRedirectResolver,
  indexedDBLocalPersistence
} from '@angular/fire/auth';
import { getApp } from '@angular/fire/app';

@NgModule({
  imports: [
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: indexedDBLocalPersistence,
        popupRedirectResolver: browserPopupRedirectResolver
      });
      return auth;
    })
  ]
})
export class AuthFirebaseModule {}
