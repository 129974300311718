import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { NotificationsDataModule } from '@gdl/notifications/browser/data';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatExpansionModule,
    NotificationsDataModule
  ],
  declarations: [NotificationsListComponent, NotificationsComponent],
  exports: [NotificationsComponent]
})
export class NotificationsUiModule {}
