import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { State } from './reducer';

const getFeatureState =
  createFeatureSelector<RouterReducerState<any>>('router');

const {
  selectCurrentRoute: getCurrentRouter,
  selectFragment: getFragment,
  selectQueryParams: getQueryParams,
  selectQueryParam: getQueryParam,
  selectRouteParams: getRouteParams,
  selectRouteParam: getRouteParam,
  selectRouteData: getRouteData,
  selectUrl: getUrl
} = getRouterSelectors(getFeatureState);

const getUrlSegments = createSelector(getUrl, (url) => {
  const urlWithoutQueryParams = (url || '').split('?').shift() || '';
  const urlWithoutOutlets = urlWithoutQueryParams.split('(').shift() || '';
  const segments = urlWithoutOutlets.split('/');
  segments.shift();

  return segments;
});

const getUrlSegment = (index: number) =>
  createSelector(getUrlSegments, (segments) => segments[index]);

export const RouterQuery = {
  getCurrentRouter,
  getFragment,
  getQueryParams,
  getQueryParam,
  getRouteParams,
  getRouteParam,
  getRouteData,
  getUrlSegment,
  getUrlSegments,
  getUrl
};
