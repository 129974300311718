export enum NavifyProduct {
  NTB = 'NTB',
  NCH = 'NCH'
}

export interface GdlAppOptions {
  url: string;
  api: {
    guidelines: string;
  };
  tenant: string;
  exportOptions: {
    keyName?: string;
    version?: string;
    addRules?: boolean;
  };
}

export interface GdlAppsOptions {
  [NavifyProduct.NTB]: GdlAppOptions;
  [NavifyProduct.NCH]: GdlAppOptions;
}
