import {
  createFeatureSelector,
  createSelector,
  createReducer,
  on
} from '@ngrx/store';
import { AuthProviderId } from '@gdl/auth/common/models';

import * as AppbarActions from './actions';

export interface State {
  visible: boolean;
  title: string;
  subtitle: string;
  userPhotoURL: string;
  providerId: AuthProviderId;
}

export const initialState: State = {
  visible: false,
  title: 'Harvester',
  subtitle: '',
  userPhotoURL: '',
  providerId: null
};

export function reducer(
  state: State = initialState,
  action: AppbarActions.ActionsUnion
): State {
  return createReducer(
    initialState,
    on(AppbarActions.hide, (s) => ({ ...s, visible: false })),
    on(AppbarActions.show, (s) => ({ ...s, visible: true })),
    on(AppbarActions.setTitle, (s, { title, subtitle }) => ({
      ...s,
      title,
      subtitle
    })),
    on(AppbarActions.setUserPhotoURL, (s, { url: userPhotoURL }) => ({
      ...s,
      userPhotoURL
    })),
    on(AppbarActions.setProviderId, (s, { providerId }) => ({
      ...s,
      providerId
    }))
  )(state, action);
}

export const getAppbarState = createFeatureSelector<State>('shellAppbar');

export const getVisible = createSelector(
  getAppbarState,
  (state) => state.visible
);

export const getTitle = createSelector(getAppbarState, (state) => state.title);

export const getSubtitle = createSelector(
  getAppbarState,
  (state) => state.subtitle
);

export const getUserPhotoURL = createSelector(
  getAppbarState,
  (state) => state.userPhotoURL
);

export const getProviderId = createSelector(
  getAppbarState,
  (state) => state.providerId
);
