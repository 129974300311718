import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Notification } from '@gdl/notifications/common/models';

import * as NotificationsActions from '../actions/notifications.actions';

export interface State extends EntityState<Notification> {
  sync: boolean;
  loading: boolean;
}

function sortByDate(a: Notification, b: Notification): number {
  return new Date(a.created).getTime() - new Date(a.created).getTime();
}

export const adapter: EntityAdapter<Notification> = createEntityAdapter<Notification>(
  {
    sortComparer: sortByDate
  }
);

export const initialState: State = adapter.getInitialState({
  sync: false,
  loading: false
});

export const reducer = createReducer(
  initialState,
  on(NotificationsActions.loading, (state) => ({ ...state, loading: true })),
  on(NotificationsActions.loaded, (state) => ({ ...state, loading: false })),
  on(NotificationsActions.syncEnabled, (state) => ({ ...state, sync: true })),
  on(NotificationsActions.syncDisabled, (state) => ({ ...state, sync: false })),
  on(NotificationsActions.added, (state, { notifications }) => {
    if (state.loading) {
      state = { ...state, loading: false };
    }
    return adapter.upsertMany(notifications, state);
  }),
  on(NotificationsActions.updated, (state, { updates }) =>
    adapter.updateMany(updates, state)
  ),
  on(NotificationsActions.removed, (state, { notificationIds }) =>
    adapter.removeMany(notificationIds, state)
  )
);
