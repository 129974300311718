import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { ShellProgressBarModule } from '@gdl/shared/browser/shell/progress-bar';

import { AppbarComponent } from './components/appbar/appbar.component';
import { StateModule } from './state/state.module';
import { AppbarService } from './services/appbar.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    MatBadgeModule,
    ShellProgressBarModule,
    StateModule
  ],
  providers: [AppbarService],
  declarations: [AppbarComponent],
  exports: [AppbarComponent]
})
export class ShellAppbarModule {}
