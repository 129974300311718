import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromMainNav from '../state/reducer';
import * as MainNavActions from '../state/actions';
import { MainNavMode } from '../types/main-nav-mode';

@Injectable()
export class MainNavService {
  readonly opened$: Observable<boolean>;
  readonly mode$: Observable<MainNavMode>;

  constructor(private store: Store<{ mainNac: fromMainNav.State }>) {
    this.opened$ = this.store.pipe(select(fromMainNav.getOpened));
    this.mode$ = this.store.pipe(select(fromMainNav.getMode));
  }

  open() {
    this.store.dispatch(MainNavActions.open());
  }

  close() {
    this.store.dispatch(MainNavActions.close());
  }
}
