import { createAction, props, union } from '@ngrx/store';

import { NavifyProduct } from '../../models';

export const gdlExport = createAction(
  '[GDL Export] Export',
  props<{
    guidelineId: string;
    data: Record<string, any>;
    product: NavifyProduct;
  }>()
);

export const gdlExportError = createAction(
  '[GDL Export] Export Error',
  props<{ error: any; product: NavifyProduct }>()
);

export const gdlExported = createAction(
  '[GDL Export] Exported',
  props<{ product: NavifyProduct }>()
);

const all = union({
  gdlExport,
  gdlExportError,
  gdlExported
});

export type ActionsUnion = typeof all;
