import { Injectable, Inject, NgZone } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { USER_HOME_ROUTE, ANONYMOUS_HOME_ROUTE } from '../tokens';

@Injectable()
export class AuthRoutingService {
  constructor(
    @Inject(USER_HOME_ROUTE) private userHomeRoute: string,
    @Inject(ANONYMOUS_HOME_ROUTE) private anonymousHomeRoute: string,
    private router: Router,
    private zone: NgZone
  ) {}

  getUserHome(): UrlTree {
    return this.router.parseUrl(this.userHomeRoute);
  }

  getAnonymousHome(): UrlTree {
    return this.router.parseUrl(this.anonymousHomeRoute);
  }

  redirectToUserHome() {
    this.zone.run(() => this.router.navigateByUrl(this.getUserHome()));
  }

  redirectToAnonymousHome() {
    this.zone.run(() => this.router.navigateByUrl(this.getAnonymousHome()));
  }
}
