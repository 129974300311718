<gdl-progress-bar></gdl-progress-bar>
<mat-toolbar *ngIf="appbar.visible$ | async">
  <button mat-icon-button class="menu-button" (click)="menu.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  <div fxLayout="column" class="title">
    {{ appbar.title$ | async }}
    <small *ngIf="appbar.subtitle$ | async as subtitle">{{ subtitle }}</small>
  </div>
  <div fxFlex></div>
  <div>
    <button mat-icon-button (click)="notifications.emit()">
      <mat-icon
        [matBadge]="notificationsCount"
        matBadgeColor="accent"
        aria-hidden="false"
        >notifications_none</mat-icon
      >
    </button>

    <button
      mat-icon-button
      class="signout-button"
      [matMenuTriggerFor]="userMenu"
    >
      <ng-container
        *ngIf="
          appbar.userPhotoURL$ | async as userPhotoURL;
          else userPlaceholder
        "
      >
        <img [src]="userPhotoURL" />
      </ng-container>

      <ng-template #userPlaceholder>
        <mat-icon>account_circle</mat-icon>
      </ng-template>
    </button>
  </div>
</mat-toolbar>

<mat-menu #userMenu="matMenu" overlapTrigger>
  <button mat-menu-item [routerLink]="['/', 'auth', 'signout']">
    Sign out
  </button>
  <ng-container *ngIf="accountLinkingAvailable$ | async">
    <button mat-menu-item [routerLink]="['/', 'auth', 'link-account']">
      Link with Google account
    </button>
    <!-- <button
      *ngIf="providerId === AuthProviderId.Google"
      mat-menu-item
      [routerLink]="['/', 'auth', 'unlink-account']"
    >
      Unlink account
    </button> -->
  </ng-container>
</mat-menu>
