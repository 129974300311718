import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import {
  Notification,
  NotificationStatus
} from '@gdl/notifications/common/models';

@Component({
  selector: 'gdl-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsListComponent {
  @Input() notifications: Notification[];

  @Output() markAsReaded = new EventEmitter<{ notification: Notification }>();
  @Output() remove = new EventEmitter<{ notification: Notification }>();

  markNotificationAsReaded(notification: Notification) {
    if (notification.status === NotificationStatus.Unreaded) {
      this.markAsReaded.emit({ notification });
    }
  }

  removeNotification(event: MouseEvent, notification: Notification) {
    event.stopPropagation();
    this.remove.emit({ notification });
  }

  trackById(idx: number, notification: Notification) {
    return notification.id;
  }
}
