import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { APP_NAME } from '@gdl/shared/browser/utils';
import { AuthProviderId } from '@gdl/auth/common/models';

import * as fromAppbar from '../state/reducer';
import * as AppbarActions from '../state/actions';

@Injectable()
export class AppbarService {
  readonly visible$: Observable<boolean>;
  readonly title$: Observable<string>;
  readonly subtitle$: Observable<string>;
  readonly userPhotoURL$: Observable<string>;
  readonly providerId$: Observable<AuthProviderId>;

  constructor(
    private store: Store<{ appbar: fromAppbar.State }>,
    @Inject(APP_NAME) private appName: string
  ) {
    this.visible$ = this.store.pipe(select(fromAppbar.getVisible));
    this.title$ = this.store.pipe(select(fromAppbar.getTitle));
    this.subtitle$ = this.store.pipe(select(fromAppbar.getSubtitle));
    this.userPhotoURL$ = this.store.pipe(select(fromAppbar.getUserPhotoURL));
    this.providerId$ = this.store.pipe(select(fromAppbar.getProviderId));
  }

  show() {
    this.store.dispatch(AppbarActions.show());
  }

  hide() {
    this.store.dispatch(AppbarActions.hide());
  }

  setTitle(title: string, subtitle?: string) {
    this.store.dispatch(
      AppbarActions.setTitle({
        title,
        subtitle
      })
    );
  }

  setDefaultTitle() {
    this.setTitle(this.appName);
  }

  setUserPhotoURL(url: string) {
    this.store.dispatch(AppbarActions.setUserPhotoURL({ url }));
  }

  setProviderId(providerId: AuthProviderId) {
    this.store.dispatch(AppbarActions.setProviderId({ providerId }));
  }
}
