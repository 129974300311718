<mat-nav-list>
  <h3 mat-subheader style="opacity: 0.64">Guidelines</h3>
  <mat-list-item routerLink="/guidelines">List</mat-list-item>
  <!-- <mat-list-item>Delete</mat-list-item> -->

  <mat-divider style="margin-top: 32px"></mat-divider>

  <ng-container *ngIf="showAdminNav">
    <h3 mat-subheader style="opacity: 0.64">Support</h3>
    <mat-list-item routerLink="/support/users">Users</mat-list-item>
    <mat-list-item routerLink="/support/guidelines">Guidelines</mat-list-item>
  </ng-container>

  <!-- <mat-list-item routerLink="/bpmn">BPMN</mat-list-item> -->

  <!-- <mat-divider style="margin-top: 32px"></mat-divider> -->

  <!-- <h3 mat-subheader style="opacity: 0.64">Preferences</h3>
  <mat-list-item routerLink="/preferences">Settings</mat-list-item> -->

  <!-- <mat-divider style="margin-top: 32px"></mat-divider> -->

  <!-- <h3 mat-subheader style="opacity: 0.64">Documentation</h3>
  <mat-list-item>Getting Started</mat-list-item> -->

  <!-- <mat-divider style="margin-top: 32px"></mat-divider> -->

  <!-- <h3 mat-subheader style="opacity: 0.64">Support</h3> -->
  <!-- <mat-list-item>FAQ</mat-list-item> -->
  <!-- <mat-list-item>Report a Problem</mat-list-item>
  <mat-list-item>Feedback</mat-list-item> -->
</mat-nav-list>
