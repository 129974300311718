import { NgModule } from '@angular/core';
import { MessengerService } from '@gdl/shared/browser/messenger';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthFirebaseModule } from '@gdl/auth/browser/firebase';

import { AuthService } from './services/auth.service';
import { AuthRoutingService } from './services/auth-routing.service';
import { AuthStateModule } from './state';
import { AuthUserGuard } from './guards/user.guard';
import { AuthAnonymousGuard } from './guards/anonymous.guard';
import { AuthAdminGuard } from './guards/admin.guard';

@NgModule({
  imports: [AuthStateModule, AuthFirebaseModule, MatSnackBarModule],
  providers: [
    AuthService,
    AuthRoutingService,
    AuthUserGuard,
    AuthAdminGuard,
    AuthAnonymousGuard,
    MessengerService
  ]
})
export class AuthCoreModule {
  constructor(authService: AuthService) {
    authService.init();
  }
}
