import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { NotificationsEffects } from './effects/notifications.effects';
import { reducers } from './reducers';
import { NotificationsFacade } from './facades/notifications.facade';

@NgModule({
  imports: [
    StoreModule.forFeature('notifications', reducers),
    EffectsModule.forFeature([NotificationsEffects])
  ],
  providers: [NotificationsFacade]
})
export class StateModule {}
