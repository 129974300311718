import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State, ExportState } from './reducers';
import { createExportSelectors } from './selectors/export.selectors';

/**
 * Feature
 */
const getFeatureState = createFeatureSelector<ExportState, State>('gdlExport');

/**
 * Export
 */
const getExportState = createSelector(getFeatureState, (state) => state.export);

export const ExportQuery = createExportSelectors(getExportState);
