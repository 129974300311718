import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as StackTrace from 'stacktrace-js';

import { ERROR_REPORTING_CONFIG } from '../tokens';
import { ErrorPayload } from '../types/error-payload';
import { API_URL } from '../const';
import { ErrorReportingConfig } from '../types/error-reporting-config';

@Injectable()
export class ErrorReportingService {
  readonly disabled: boolean = false;
  readonly bypass: boolean = false;

  private apiKey: string;
  private projectId: string;
  private context: Record<string, any> = {};
  private service = 'app';
  private version: string | undefined;
  private reportUncaughtExceptions = true;
  private reportUnhandledPromiseRejections = false;

  private get serviceContext() {
    const context = {
      service: this.service
    };

    if (this.version) {
      const version = this.version;

      return {
        ...context,
        version
      };
    }

    return context;
  }

  constructor(
    private readonly http: HttpClient,
    @Inject(ERROR_REPORTING_CONFIG)
    config: ErrorReportingConfig
  ) {
    this.apiKey = config.apiKey;
    this.projectId = config.projectId;
    this.service = config.service || this.service;
    this.version = config.version;
    this.disabled = config.disabled || this.disabled;
    this.bypass = config.bypass || this.bypass;
    this.reportUncaughtExceptions =
      config.reportUncaughtExceptions || this.reportUncaughtExceptions;
    this.reportUnhandledPromiseRejections =
      config.reportUnhandledPromiseRejections ||
      this.reportUnhandledPromiseRejections;

    this.init();
  }

  report(err: any) {
    if (this.disabled) {
      return Promise.resolve(null);
    }
    if (!err) {
      return Promise.reject(new Error('no error to report'));
    }

    const payload: ErrorPayload = {};
    payload['serviceContext'] = this.serviceContext;
    payload['context'] = this.context;
    payload['context'].httpRequest = {
      userAgent: window.navigator.userAgent,
      url: window.location.href
    };

    let firstFrameIndex = 0;

    if (typeof err === 'string') {
      try {
        throw new Error(err);
      } catch (e) {
        err = e;
      }
      firstFrameIndex = 1;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    return StackTrace.fromError(err)
      .then(
        function (stack) {
          const lines = [err.toString()];

          for (let s = firstFrameIndex; s < stack.length; s++) {
            lines.push(
              [
                '    at ',
                stack[s].getFunctionName() || '<anonymous>',
                ' (',
                stack[s].getFileName(),
                ':',
                stack[s].getLineNumber(),
                ':',
                stack[s].getColumnNumber(),
                ')'
              ].join('')
            );
          }
          return lines.join('\n');
        },
        function (reason) {
          return [
            'Error extracting stack trace: ',
            reason,
            '\n',
            err.toString(),
            '\n',
            '    (',
            err.file,
            ':',
            err.line,
            ':',
            err.column,
            ')'
          ].join('');
        }
      )
      .then(function (message) {
        payload['message'] = message;
        return that.sendErrorPayload(payload);
      });
  }

  setUser(user: string = '') {
    if (user) {
      this.context = {
        ...(this.context || {}),
        user
      };
    } else {
      const { user: _, ...context } = this.context;
      this.context = context;
    }
  }

  private sendErrorPayload(payload: ErrorPayload) {
    const url = API_URL + this.projectId + '/events:report?key=' + this.apiKey;

    return this.http.post(url, payload).toPromise();
  }

  private init() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const noop: any = () => {};
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    if (this.reportUncaughtExceptions) {
      const oldErrorHandler = window.onerror || noop;

      window.onerror = function (message, source, lineno, colno, error) {
        if (error) {
          that.report(error).catch(noop);
        }
        oldErrorHandler(message, source, lineno, colno, error);

        return true;
      };
    }

    if (this.reportUnhandledPromiseRejections) {
      const oldPromiseRejectionHandler = window.onunhandledrejection || noop;

      window.onunhandledrejection = function (promiseRejectionEvent) {
        if (promiseRejectionEvent) {
          that.report(promiseRejectionEvent.reason).catch(noop);
        }
        oldPromiseRejectionHandler(promiseRejectionEvent.reason);

        return true;
      };
    }
  }
}
