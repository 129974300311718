import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Notification } from '@gdl/notifications/common/models';
import { Observable } from 'rxjs';

import * as NotificationActions from '../actions/notifications.actions';
import { NotificationsState } from '../reducers';
import { NotificationsQuery } from '../selectors';

@Injectable()
export class NotificationsFacade {
  readonly all$ = this.store.select(NotificationsQuery.getAll);
  readonly unreaded$ = this.store.select(NotificationsQuery.getUnreaded);
  readonly unreadedCount$ = this.store.select(
    NotificationsQuery.getUnreadedCount
  );
  readonly loading$ = this.store.select(NotificationsQuery.getLoading);
  readonly sync$ = this.store.select(NotificationsQuery.getSync);

  constructor(private store: Store<NotificationsState>) {}

  markAsReaded(notificationId: string) {
    this.store.dispatch(NotificationActions.markAsReaded({ notificationId }));
  }

  markAllAsReaded() {
    this.store.dispatch(NotificationActions.markAllAsReaded());
  }

  getById(notificationId: string): Observable<Notification> {
    return this.store.select(NotificationsQuery.getById, notificationId);
  }

  save(notification: Partial<Notification>) {
    this.store.dispatch(NotificationActions.update({ notification }));
  }

  update(notification: Partial<Notification>) {
    this.store.dispatch(NotificationActions.update({ notification }));
  }

  delete(notificationId: string) {
    this.store.dispatch(NotificationActions.remove({ notificationId }));
  }

  enableSync() {
    this.store.dispatch(NotificationActions.enableSync());
  }

  disableSync() {
    this.store.dispatch(NotificationActions.disableSync());
  }

  loading() {
    this.store.dispatch(NotificationActions.loading());
  }

  loaded() {
    this.store.dispatch(NotificationActions.loaded());
  }
}
