import { createAction, union } from '@ngrx/store';

export const open = createAction('[Shell Main Nav] Open');

export const close = createAction('[Shell Main Nav] Close');

const all = union({
  open,
  close
});

export type ActionsUnion = typeof all;
