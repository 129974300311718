import { createAction, props, union } from '@ngrx/store';
import { Notification } from '@gdl/notifications/common/models';
import { Update } from '@ngrx/entity';

export const markAsReaded = createAction(
  '[Notifications] Mark as Readed',
  props<{
    notificationId: string;
  }>()
);

export const markAllAsReaded = createAction(
  '[Notifications] Mark all as Readed'
);

export const loading = createAction('[Notifications] Loading');

export const loaded = createAction('[Notifications] Loaded');

export const enableSync = createAction('[Notifications] Enable Sync');

export const syncEnabled = createAction('[Notifications] Sync Enabled');

export const disableSync = createAction('[Notifications] Disable Sync');

export const syncDisabled = createAction('[Notifications] Sync Disabled');

export const add = createAction(
  '[Notifications] Add',
  props<{
    notification: Notification;
  }>()
);

export const added = createAction(
  '[Notifications] Added',
  props<{ notifications: Notification[] }>()
);

export const updated = createAction(
  '[Notifications] Updated',
  props<{ updates: Update<Notification>[] }>()
);

export const remove = createAction(
  '[Notifications] Remove',
  props<{ notificationId: string }>()
);

export const removed = createAction(
  '[Notifications] Removed',
  props<{ notificationIds: string[] }>()
);

export const update = createAction(
  '[Notifications] Update',
  props<{ notification: Partial<Notification> }>()
);

const all = union({
  markAsReaded,
  markAllAsReaded,
  loading,
  loaded,
  enableSync,
  syncEnabled,
  disableSync,
  syncDisabled,
  add,
  added,
  update,
  updated,
  removed,
  remove
});

export type ActionsUnion = typeof all;
