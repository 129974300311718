import { Component } from '@angular/core';
import {
  MainNavService,
  MainNavMode
} from '@gdl/harvester/browser/shell/main-nav';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { NotificationsFacade } from '@gdl/notifications/browser/data';
import { AuthService } from '@gdl/auth/browser/core';

@Component({
  selector: 'gdl-havester',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  readonly mainNavOpened$: Observable<boolean>;
  readonly mainNavMode$: Observable<MainNavMode>;
  readonly notificationsOpened$ = new BehaviorSubject(false);
  readonly notificationsCount$: Observable<number>;
  readonly showAdminNav$: Observable<boolean>;

  constructor(
    private mainNav: MainNavService,
    private notificationsFacade: NotificationsFacade,
    private authService: AuthService
  ) {
    this.mainNavOpened$ = this.mainNav.opened$;
    this.mainNavMode$ = this.mainNav.mode$;
    this.notificationsCount$ = this.notificationsFacade.unreadedCount$;
    this.showAdminNav$ = this.authService.user$.pipe(
      map((user) => user?.isAdmin)
    );
  }

  onMainNavOpen() {
    this.mainNav.open();
  }

  onMainNavClosed() {
    this.mainNav.close();
  }

  onNotificationsOpen() {
    this.notificationsOpened$.next(true);
  }

  onNotificationsClosed() {
    this.notificationsOpened$.next(false);
    this.notificationsFacade.markAllAsReaded();
  }
}
