import { Injectable } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  updateDoc,
  deleteDoc,
  docData,
  collectionChanges,
  collectionData,
  CollectionReference,
  DocumentReference,
  orderBy,
  query
} from '@angular/fire/firestore';
import { AuthService } from '@gdl/auth/browser/core';
import { FirestoreBatch } from '@gdl/firestore/browser/core';
import {
  getUserNotificationsCollectionPath,
  getUserNotificationDocumentPath
} from '@gdl/notifications/common/data';
import {
  Notification,
  NotificationStatus
} from '@gdl/notifications/common/models';
import { Observable, merge } from 'rxjs';
import {
  take,
  switchMap,
  map,
  mergeMap,
  takeUntil,
  filter,
  first
} from 'rxjs/operators';
import { traceUntilFirst } from '@angular/fire/performance';

@Injectable()
export class NotificationsBackend {
  constructor(private firestore: Firestore, private authService: AuthService) {}

  save(notification: Partial<Notification>) {
    return this.getDoc(notification.id).pipe(
      mergeMap((doc) => updateDoc(doc, notification))
    );
  }

  changes() {
    return this.authService.user$.pipe(
      take(1),
      filter((user) => !!user),
      switchMap(({ id: userId }) => {
        const collectionRef = query(
          this.getCollectionRef(userId),
          orderBy('created', 'desc')
        );

        return merge(
          collectionData(collectionRef).pipe(
            first(),
            filter((data) => !data.length)
          ),
          collectionChanges(collectionRef)
        );
      }),
      traceUntilFirst('listNotifications'),
      takeUntil(this.authService.beforeSignOut$)
    );
  }

  getById(notificationId: string): Observable<Notification> {
    return this.getDoc(notificationId).pipe(mergeMap((doc) => docData(doc)));
  }

  deleteById(notificationId: string): Observable<void> {
    return this.getDoc(notificationId).pipe(mergeMap((doc) => deleteDoc(doc)));
  }

  markAllAsReaded(notifications: Notification[]) {
    return this.getUserId().pipe(
      mergeMap((userId) => {
        const batch = new FirestoreBatch(this.firestore);

        for (let notification of notifications) {
          const ref = this.getDocRef(userId, notification.id);
          batch.update(ref, {
            status: NotificationStatus.Readed
          });
        }
        return batch.complete();
      })
    );
  }

  private getDoc(notifictionId: string) {
    return this.getUserId().pipe(
      map((userId) => this.getDocRef(userId, notifictionId))
    );
  }

  private getCollection() {
    return this.getUserId().pipe(
      map((userId) => this.getCollectionRef(userId))
    );
  }

  private getDocRef(userId: string, notificationId: string) {
    return doc(
      this.firestore,
      getUserNotificationDocumentPath(userId, notificationId)
    ) as DocumentReference<Notification>;
  }

  private getCollectionRef(userId: string) {
    return collection(
      this.firestore,
      getUserNotificationsCollectionPath(userId)
    ) as CollectionReference<Notification>;
  }

  private getUserId() {
    return this.authService.user$.pipe(
      filter((user) => !!user),
      take(1),
      filter((user) => !!user),
      map(({ id }) => id)
    );
  }
}
