import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorReportingService } from '@gdl/shared/browser/error-reporting';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(private readonly reporter: ErrorReportingService) {
    super();
  }

  handleError(error: any): void {
    this.reporter.report(error);

    if (this.reporter.disabled || this.reporter.bypass) {
      super.handleError(error);
    }
  }
}
