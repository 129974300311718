import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { uuid } from '@gdl/shared/utils';

export interface ProgressBarTrigger {
  token: string;
  destroy: () => void;
}

@Injectable()
export class ProgressBarService {
  readonly active$: Observable<boolean>;

  private state$ = new BehaviorSubject<any>({});
  private subscriptions = new Map<string, Subscription>();

  constructor() {
    this.active$ = this.state$.pipe(
      map((state) => Object.values(state).some(Boolean))
    );
  }

  addTrigger(trigger: Observable<boolean>): ProgressBarTrigger {
    const token = uuid();
    const next = (value: boolean) => this.setTokenValue(token, value);
    const error = () => this.removeTrigger(token);
    const complete = () => this.removeTrigger(token);
    const subscription = trigger.subscribe({ next, error, complete });

    this.subscriptions.set(token, subscription);

    return {
      destroy: () => this.removeTrigger(token),
      token
    };
  }

  removeTrigger(token: string) {
    if (this.subscriptions.has(token)) {
      this.subscriptions.get(token)?.unsubscribe();
      this.subscriptions.delete(token);

      const state = { ...this.state$.getValue() };
      delete state[token];

      this.state$.next(state);
    }
  }

  private setTokenValue(token: string, value: boolean) {
    const state = this.state$.getValue();

    state[token] = value;
    this.state$.next(state);
  }
}
