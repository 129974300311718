import * as R from 'ramda';

const CACHE_KEY = Symbol('cache');
let disabled = false;

export function Memo(idFn: (...args: any[]) => any = R.identity) {
  return function (
    _target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const oldMethod = descriptor.value;

    descriptor.value = function (...args) {
      if (!disabled) {
        this[CACHE_KEY] = this[CACHE_KEY] || {};
        const methodCache = (this[CACHE_KEY][propertyKey] =
          this[CACHE_KEY][propertyKey] || {});

        const id = idFn(...args);

        if (!methodCache[id]) {
          methodCache[id] = oldMethod.apply(this, args);
        }

        return methodCache[id];
      } else {
        return oldMethod.apply(this, args);
      }
    };
  };
}

Memo.free = (obj) => (obj[CACHE_KEY] = {});
Memo.dump = (obj) => console.log(obj[CACHE_KEY]);
Memo.disable = () => (disabled = true);
Memo.enable = () => (disabled = false);
Memo.hashFromIds = () => (items: { id: string }[] & { __id?: string }) => {
  if (!items) {
    return '__DEFAULT__';
  }
  if (items.__id) {
    return items.__id;
  }
  items.__id = items.map(({ id }) => id).join('|');

  return items.__id;
};
Memo.hashFromId = () => (item: { id: string }) =>
  item ? item.id : '__DEFAULT__';
Memo.hashFromProps = () => (item: Record<string, any>) =>
  Object.values(item).join('|');
Memo.hashFromArgs = () => (...args: Record<string, any>[]) =>
  args
    .reduce((aggr, arg) => {
      if (
        typeof arg === 'boolean' ||
        typeof arg === 'number' ||
        typeof arg === 'string'
      ) {
        aggr.push(arg);
      } else {
        aggr.push(
          Object.keys(arg)
            .map((key) => `${key}:${arg[key]}`)
            .join('|')
        );
      }

      return aggr;
    }, [])
    .join('||');
Memo.hashFromBounds = () => (item: {
  x: number;
  y: number;
  width: number;
  height: number;
}) => [item.x, item.y, item.width, item.height].join('|');
