import { f, classToPlain, plainToClass } from '@marcj/marshal';

import { Role } from './role';
import { AccessControlListDTO } from './access-control-list.dto';

export class AccessControlList implements AccessControlListDTO {
  @f.array(String)
  users: string[] = [];

  @f.map(String)
  roles: Record<string, Role> = {};

  static create(
    data: AccessControlListDTO = { users: [], roles: {} }
  ): AccessControlList {
    return plainToClass(AccessControlList, data);
  }

  hasUser(userId: string): boolean {
    return !!this.getUserRole(userId);
  }

  getUserRole(userId: string): Role {
    return this.roles[userId];
  }

  getUsersWithRole(role: Role): string[] {
    return this.users.filter((userId) => this.getUserRole(userId) === role);
  }

  hasUserWithRole(userId: string, role: Role): boolean {
    return this.hasUser(userId) && this.getUserRole(userId) === role;
  }

  addUser(userId: string, role: Role): void {
    if (!this.hasUser(userId)) {
      this.users.push(userId);
      this.roles[userId] = role;
    }
  }

  deleteUser(userId: string): void {
    if (this.hasUser(userId)) {
      this.users = this.users.filter((uid) => uid !== userId);
      delete this.roles[userId];
    }
  }

  toJSON(): AccessControlListDTO {
    return classToPlain(AccessControlList, this);
  }
}
