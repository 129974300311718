import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressBarService } from './services/progress-bar.service';

@NgModule({
  imports: [CommonModule, MatProgressBarModule],
  providers: [ProgressBarService],
  declarations: [ProgressBarComponent],
  exports: [ProgressBarComponent]
})
export class ShellProgressBarModule {}
