import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { USER_HOME_ROUTE, ANONYMOUS_HOME_ROUTE } from '@gdl/auth/browser/core';
import { AUTOTRAVERSAL_RULES_VERSION } from '@gdl/harvester/browser/environment';

import { environment } from '../environments/environment';

// Auth
export const AUTH_PROVIDERS = [
  {
    provide: USER_HOME_ROUTE,
    useValue: environment.redirects.user
  },
  {
    provide: ANONYMOUS_HOME_ROUTE,
    useValue: environment.redirects.anonymous
  }
];

export const AUTOTRAVERSAL_PROVIDERS = [
  {
    provide: AUTOTRAVERSAL_RULES_VERSION,
    useValue: environment.autotraversal.version
  }
];

// Material
export const MATERIAL_DEFAULTS_PROVIDERS = [
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      showDelay: 500
    }
  }
  // {
  //   provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  //   useValue: { appearance: 'standard' }
  // }
];
