import { Role } from '../role';
import { RoleOption } from '../role-option';

const allRoleOptions: RoleOption[] = [
  RoleOption.create({
    role: Role.Owner,
    label: 'Owner'
  }),
  RoleOption.create({
    role: Role.Editor,
    label: 'Editor'
  }),
  RoleOption.create({
    role: Role.Reviewer,
    label: 'Reviewer'
  }),
  RoleOption.create({
    role: Role.Viewer,
    label: 'Viewer'
  })
];

export function getRoleOptions(without: Role[] = []): RoleOption[] {
  return allRoleOptions.filter((option) => without.indexOf(option.role) === -1);
}
