import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { ENVIRONMENT, Environment } from '@gdl/harvester/browser/environment';

import { OktaSigninComponent } from './components/okta-signin/okta-signin.component';
import { OktaAuthService } from './services/okta-auth.service';

@NgModule({
  imports: [CommonModule, HttpClientModule, MatDialogModule, OktaAuthModule],
  providers: [
    OktaAuthService,
    {
      provide: OKTA_CONFIG,
      useFactory: (environment: Environment) => {
        const oktaAuth = new OktaAuth({
          issuer: `${environment.okta.orgUrl}${environment.okta.issuerPath}`,
          redirectUri: window.location.origin,
          clientId: environment.okta.clientId
        });
        return {
          oktaAuth
        };
      },
      deps: [ENVIRONMENT]
    }
  ],
  declarations: [OktaSigninComponent]
})
export class AuthOktaModule {}
