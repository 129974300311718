import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { from, Observable, merge, Subject, Observer } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';
import {
  OktaAuthStateService as OktaService,
  OKTA_AUTH
} from '@okta/okta-angular';
import { OktaAuth, AuthState, Tokens } from '@okta/okta-auth-js';
import { JwtHelperService } from '@auth0/angular-jwt';

import { OktaSigninComponent } from '../components/okta-signin/okta-signin.component';
import { OktaOptions } from '../models/okta-options';
import { OKTA_OPTIONS } from '../tokens';

@Injectable()
export class OktaAuthService {
  readonly oktaAuthState$: Observable<boolean>;
  readonly state$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  private tokenIsValid$ = new Subject<boolean>();
  private jwtHelper = new JwtHelperService();

  constructor(
    private matDialog: MatDialog,
    private http: HttpClient,
    private oktaService: OktaService,
    @Inject(OKTA_OPTIONS) private options: OktaOptions,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth
  ) {
    this.oktaAuthState$ = this.oktaService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );

    this.state$ = merge(this.oktaAuthState$, this.tokenIsValid$);

    // Triggered when a token has expired
    this.oktaAuth.tokenManager.on('expired', () => {
      this.refreshToken();
    });

    // https://developer.okta.com/code/angular/okta_angular_auth_js/#create-an-authentication-service
    this.isAuthenticated$ = merge(
      new Observable((observer: Observer<boolean>) => {
        this.hasAccessToken().then((val) => {
          observer.next(val);
        });
      }),
      this.tokenIsValid$
    );
  }

  signIn() {
    const dialogRef = this.matDialog.open(OktaSigninComponent, {
      disableClose: true
    });

    return dialogRef.afterClosed().pipe(
      filter(Boolean),
      map(
        ({
          accessToken: { accessToken: accessToken },
          idToken: {
            claims: { name, email }
          }
        }) => ({ accessToken, name, email })
      ),
      switchMap(({ accessToken, name, email }) =>
        this.http
          .get<{ customToken: string }>(this.options.customTokenUrl, {
            headers: { authorization: `Bearer ${accessToken}` }
          })
          .pipe(
            map(({ customToken }) => ({
              customToken,
              email,
              name
            }))
          )
      )
    );
  }

  getOktaToken() {
    return from(
      this.oktaAuth.tokenManager.get('accessToken').then((token) => {
        this.isTokenExpired(token['accessToken']);
        return (token && token['accessToken']) || null;
      })
    );
  }

  async setTokens(tokens: Tokens) {
    this.oktaAuth.tokenManager.setTokens(tokens);

    if (await this.hasAccessToken()) {
      this.tokenIsValid$.next(true);
    } else {
      this.tokenIsValid$.next(false);
    }
  }

  private refreshToken() {
    this.oktaAuth.token
      .getWithoutPrompt()
      .then((response) => {
        this.setTokens(response.tokens);
      })
      .catch(() => {
        this.oktaAuth.tokenManager.clear();
        this.tokenIsValid$.next(false);
      });
  }

  private async hasAccessToken() {
    const token = await this.oktaAuth.tokenManager.get('accessToken');

    return !!token && !this.isTokenExpired(token['accessToken']);
  }

  private isTokenExpired(token: string) {
    // TODO https://devforum.okta.com/t/jwtparseerror-jwt-is-expired-and-isauthenticated-is-always-returning-true/1543
    try {
      const isTokenExpired = this.jwtHelper.isTokenExpired(token);
      if (isTokenExpired) {
        this.tokenIsValid$.next(false);
      }

      return isTokenExpired;
    } catch {
      this.tokenIsValid$.next(false);

      return true;
    }
  }
}
