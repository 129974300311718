import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';

import { MainNavComponent } from './components/main-nav/main-nav.component';
import { MainNavService } from './services/main-nav.service';
import { StateModule } from './state/state.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatListModule,
    StateModule
  ],
  providers: [MainNavService],
  declarations: [MainNavComponent],
  exports: [MainNavComponent]
})
export class ShellMainNavModule {}
