import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MessengerModule } from '@gdl/shared/browser/messenger';

import { ExportService } from './services/export.service';
import { ExportEffects } from './state/effects/export.effects';
import { ExportFacade } from './state/facades/export.facade';
import { reducers } from './state/reducers';

@NgModule({
  imports: [
    CommonModule,
    MessengerModule,
    StoreModule.forFeature('gdlExport', reducers),
    EffectsModule.forFeature([ExportEffects])
  ],
  providers: [ExportService, ExportFacade]
})
export class GdlExportModule {}
