import { NgModule, ModuleWithProviders } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import { ErrorReportingService } from './services/error-reporting.service';
import { ERROR_REPORTING_CONFIG } from './tokens';
import { ErrorReportingConfig } from './types/error-reporting-config';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function reporterServiceFactory(
  platform: Platform,
  httpClient: HttpClient,
  config: any
) {
  if (platform.isBrowser) {
    return new ErrorReportingService(httpClient, config);
  }
  return {
    report: (...args: any[]) => console.log(...args)
  };
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: ErrorReportingService,
      useFactory: reporterServiceFactory,
      deps: [Platform, HttpClient, ERROR_REPORTING_CONFIG]
    }
  ]
})
export class ErrorReportingModule {
  static forRoot(
    config: ErrorReportingConfig
  ): ModuleWithProviders<ErrorReportingModule> {
    return {
      ngModule: ErrorReportingModule,
      providers: [{ provide: ERROR_REPORTING_CONFIG, useValue: config }]
    };
  }
}
