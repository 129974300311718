import { Subject, from, Observable } from 'rxjs';
import { bufferCount, mergeMap, tap, last, delay, map } from 'rxjs/operators';
import {
  Firestore,
  DocumentReference,
  doc,
  writeBatch
} from '@angular/fire/firestore';

export enum FirestoreBatchOpType {
  Create,
  Delete,
  Set,
  Update
}

export interface FirestoreBatchOp {
  type: FirestoreBatchOpType;
  ref: DocumentReference;
  data: any;
  params?: Record<string, any>;
}

export interface FirestoreBatchOptions {
  size: number;
  concurrency: number;
  delay: number;
}

export class FirestoreBatch {
  get results() {
    return this._results.asObservable();
  }
  private readonly _results = new Subject();
  private readonly ops = new Subject<FirestoreBatchOp>();
  private readonly options: FirestoreBatchOptions;

  constructor(
    private firestore: Firestore,
    options: Partial<FirestoreBatchOptions> = {}
  ) {
    this.options = {
      size: 200,
      concurrency: 1,
      delay: 50,
      ...options
    };

    if (this.options.size < 1 || this.options.size > 500) {
      throw new Error('Invalid batch size.');
    }

    if (this.options.concurrency < 1 || this.options.concurrency > 500) {
      throw new Error('Invalid batch concurrency.');
    }

    if (this.options.delay < 0) {
      throw new Error('Invalid batch delay.');
    }

    this.init();
  }

  set<T = Record<string, any>>(ref: DocumentReference | string, data: T) {
    const normalizedRef = this.normalizeRef(ref);
    this.op(FirestoreBatchOpType.Set, normalizedRef, data);
  }

  update<T = Record<string, any>>(ref: DocumentReference | string, data: T) {
    const normalizedRef = this.normalizeRef(ref);
    this.op(FirestoreBatchOpType.Update, normalizedRef, data);
  }

  create<T = Record<string, any>>(ref: DocumentReference | string, data: T) {
    const normalizedRef = this.normalizeRef(ref);
    this.op(FirestoreBatchOpType.Create, normalizedRef, data);
  }

  delete(ref: DocumentReference | string) {
    const normalizedRef = this.normalizeRef(ref);
    this.op(FirestoreBatchOpType.Delete, normalizedRef, null);
  }

  // TODO: return Observable instead Promise
  async complete() {
    this.ops.complete();

    return this._results.toPromise();
  }

  private normalizeRef(ref: DocumentReference | string): DocumentReference {
    return typeof ref === 'string' ? doc(this.firestore, ref) : ref;
  }

  private init() {
    this.ops
      .pipe(
        bufferCount(this.options.size),
        mergeMap((ops) => this.process(ops), this.options.concurrency)
      )
      .subscribe({
        complete: () => this._results.complete()
      });
  }

  private process(ops: FirestoreBatchOp[]): Observable<void> {
    const batch = writeBatch(this.firestore);

    for (const op of ops) {
      const data = op.data?.toJSON ? op.data.toJSON() : op.data;

      switch (op.type) {
        // TODO
        // case FirestoreBatchOpType.Create:
        //   batch.create(op.ref, data);
        //   break;
        case FirestoreBatchOpType.Delete:
          batch.delete(op.ref);
          break;
        case FirestoreBatchOpType.Update:
          batch.update(op.ref, data);
          break;
        case FirestoreBatchOpType.Set:
          batch.set(op.ref, data);
          break;
      }
    }

    return from(batch.commit()).pipe(
      // TODO
      // mergeMap((results) => from(results)),
      tap((result) => this._results.next(result)),
      last(),
      delay(this.options.delay),
      map(() => {})
    );
  }

  private op(
    type: FirestoreBatchOpType,
    ref: DocumentReference,
    data: any
  ): void {
    this.ops.next({
      type,
      ref,
      data
    });
  }
}
