<mat-nav-list *ngIf="notifications.length > 0; else message">
  <mat-accordion multi displayMode="flat">
    <mat-expansion-panel
      hideToggle
      [expanded]="notification.status === 'unreaded'"
      *ngFor="let notification of notifications; trackBy: trackById"
    >
      <mat-expansion-panel-header
        (click)="markNotificationAsReaded(notification)"
      >
        <mat-panel-title>
          <mat-icon [ngClass]="notification.icon" matListIcon>{{
            notification.icon || notification.type
          }}</mat-icon>
          <span [ngClass]="{ unreaded: notification.status === 'unreaded' }">{{
            notification.title
          }}</span></mat-panel-title
        >
        <button
          (click)="removeNotification($event, notification)"
          matTooltip="Remove"
          matTooltipPosition="left"
          mat-icon-button
          tabindex="-1"
          class="remove-notification"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </mat-expansion-panel-header>
      <p>{{ notification.content }}</p>
    </mat-expansion-panel>
  </mat-accordion>
</mat-nav-list>

<ng-template #message>
  <div style="padding: 120px 32px; text-align: center">
    <p>You don't have any alerts.</p>
  </div>
</ng-template>
