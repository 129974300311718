import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { State } from './reducer';
import { RouterQuery } from './selectors';

@Injectable()
export class RouterFacade {
  currentRouter$ = this.select(RouterQuery.getCurrentRouter);
  queryParam$ = this.select(RouterQuery.getQueryParams);
  routeParams$ = this.select(RouterQuery.getRouteParams);
  routeData$ = this.select(RouterQuery.getRouteData);
  url$ = this.select(RouterQuery.getUrl);
  fragment$ = this.select(RouterQuery.getFragment);
  urlSegments$ = this.select(RouterQuery.getUrlSegments);

  constructor(private store: Store<State>) {}

  getQueryParam(param: string) {
    return this.select(RouterQuery.getQueryParam(param));
  }

  getRouteParam(param: string) {
    return this.select(RouterQuery.getRouteParam(param));
  }

  getUrlSegment(index: number) {
    return this.select(RouterQuery.getUrlSegment(index));
  }

  private select(queryFn) {
    return this.store.pipe(select(queryFn));
  }
}
