import { Injectable } from '@angular/core';
import {
  Firestore,
  CollectionReference,
  DocumentReference,
  collection,
  doc,
  DocumentData
} from '@angular/fire/firestore';
import {
  FirestoreBatch,
  FirestoreBatchOptions
} from '../utils/firestore-batch';

@Injectable()
export class FirestoreService {
  constructor(public connection: Firestore) {}

  batch(options: Partial<FirestoreBatchOptions> = {}): FirestoreBatch {
    const batch = new FirestoreBatch(this.connection, options);
    return batch;
  }

  collection<T = DocumentData>(path: string): CollectionReference<T> {
    return collection(this.connection, path) as CollectionReference<T>;
  }

  doc<T = DocumentData>(path: string): DocumentReference {
    return doc(this.connection, path);
  }

  generateId() {
    return this.doc('').id;
  }
}
