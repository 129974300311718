import { createSelector } from '@ngrx/store';

import * as fromExport from '../reducers/export.reducer';
import { NavifyProduct } from '../../models';

export function createExportSelectors<V>(
  selectState: (state: V) => fromExport.State
) {
  const getExporting = createSelector(
    selectState,
    (state) =>
      state.exporting[NavifyProduct.NTB] || state.exporting[NavifyProduct.NCH]
  );

  return {
    getExporting
  };
}
