import { classToPlain, f, plainToClass } from '@marcj/marshal';
import { Role } from './role';
import { RoleOptionDTO } from './role-option.dto';

export class RoleOption {
  @f.enum(Role)
  role: Role;

  @f
  label: string;

  static create(data: RoleOptionDTO) {
    return plainToClass(RoleOption, data);
  }

  toJSON(): RoleOptionDTO {
    return classToPlain(RoleOption, this);
  }
}
