<mat-sidenav-container fxFlex>
  <!-- Left Sidenav -->
  <mat-sidenav
    [mode]="mainNavMode$ | async"
    [opened]="mainNavOpened$ | async"
    (closed)="onMainNavClosed()"
  >
    <gdl-main-nav [showAdminNav]="showAdminNav$ | async"></gdl-main-nav>
  </mat-sidenav>
  <!-- /Left Sidenav -->

  <!-- Main Content -->
  <mat-sidenav-content fxLayout="column">
    <gdl-appbar
      (menu)="onMainNavOpen()"
      (notifications)="onNotificationsOpen()"
      [notificationsCount]="notificationsCount$ | async"
    ></gdl-appbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <!-- /Main Content -->

  <!-- Right Sidenav -->
  <mat-sidenav
    [opened]="notificationsOpened$ | async"
    position="end"
    (closed)="onNotificationsClosed()"
    class="right-sidenav"
  >
    <gdl-notifications></gdl-notifications>
  </mat-sidenav>
  <!-- /Right Sidenav -->
</mat-sidenav-container>

<router-outlet name="external"></router-outlet>
