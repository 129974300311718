import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationsFacade } from '@gdl/notifications/browser/data';
import { Notification } from '@gdl/notifications/common/models';

@Component({
  selector: 'gdl-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent {
  readonly all$: Observable<Notification[]>;

  constructor(private notificationsFacade: NotificationsFacade) {
    this.all$ = this.notificationsFacade.all$;
  }

  onMarkAsReaded({ notification }: { notification: Notification }) {
    this.notificationsFacade.markAsReaded(notification.id);
  }

  onRemove({ notification }: { notification: Notification }) {
    this.notificationsFacade.delete(notification.id);
  }
}
