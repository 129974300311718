import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  mergeMap,
  takeUntil,
  repeatWhen,
  map,
  withLatestFrom,
  filter,
  exhaustMap
} from 'rxjs/operators';
import {
  NotificationStatus,
  Notification
} from '@gdl/notifications/common/models';
import { ProgressBarService } from '@gdl/shared/browser/shell/progress-bar';
import { changeActionsToNgrxActions } from '@gdl/firestore/browser/utils';
import { AuthService } from '@gdl/auth/browser/core';

import * as NotificationsActions from '../actions/notifications.actions';
import { NotificationsFacade } from '../facades/notifications.facade';
import { NotificationsBackend } from '../../backend/services/notifications.backend';

@Injectable()
export class NotificationsEffects {
  syncEnable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.enableSync),
      withLatestFrom(this.notificationsFacade.sync$),
      filter(([, enabled]) => !enabled),
      map(() => NotificationsActions.syncEnabled())
    )
  );

  syncDisable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.disableSync),
      withLatestFrom(this.notificationsFacade.sync$),
      filter(([, enabled]) => enabled),
      map(() => NotificationsActions.syncDisabled())
    )
  );

  sync$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.syncEnabled),
      exhaustMap(() => {
        this.notificationsFacade.loading();

        return this.notificationsBackend.changes();
      }),
      mergeMap(
        changeActionsToNgrxActions<Notification>({
          added: (notifications) =>
            NotificationsActions.added({ notifications }),
          modified: (updates) => NotificationsActions.updated({ updates }),
          removed: (notificationIds) =>
            NotificationsActions.removed({ notificationIds }),
          loaded: () => NotificationsActions.loaded()
        })
      ),
      takeUntil(this.actions$.pipe(ofType(NotificationsActions.syncDisabled))),
      repeatWhen(() => this.authService.reSignedIn$)
    )
  );

  remove$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsActions.remove),
        mergeMap(({ notificationId }) =>
          this.notificationsBackend.deleteById(notificationId)
        )
      ),
    { dispatch: false }
  );

  update$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsActions.update),
        mergeMap(({ notification }) => {
          return this.notificationsBackend.save(notification);
        })
      ),
    { dispatch: false }
  );

  markAsReaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationsActions.markAsReaded),
      map(({ notificationId }) =>
        NotificationsActions.update({
          notification: {
            id: notificationId,
            status: NotificationStatus.Readed
          }
        })
      )
    )
  );

  markAllAsReaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(NotificationsActions.markAllAsReaded),
        withLatestFrom(this.notificationsFacade.unreaded$),
        mergeMap(([, unreaded]) =>
          this.notificationsBackend.markAllAsReaded(unreaded)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notificationsBackend: NotificationsBackend,
    private notificationsFacade: NotificationsFacade,
    private authService: AuthService,
    progressBarService: ProgressBarService
  ) {
    progressBarService.addTrigger(this.notificationsFacade.loading$);
  }
}
