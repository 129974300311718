import { combineReducers, Action } from '@ngrx/store';

import * as fromNotifications from './reducers/notifications.reducer';

export interface State {
  notifications: fromNotifications.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    notifications: fromNotifications.reducer
  })(state, action);
}

export interface NotificationsState {
  notifications: State;
}
