import { NgModule } from '@angular/core';
import { provideFirestore } from '@angular/fire/firestore';
import { getFirestore } from '@firebase/firestore';

import { FirestoreService } from './services/firestore.service';

@NgModule({
  imports: [provideFirestore(() => getFirestore())],
  providers: [FirestoreService]
})
export class FirestoreModule {}
