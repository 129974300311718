import { classToPlain, f, plainToClass } from '@marcj/marshal';

import { AuthProviderId } from './auth-provider-id';
import { UserDTO } from './user.dto';

export class User implements UserDTO {
  @f
  id: string;

  @f
  name: string;

  @f
  email: string;

  @f
  photoURL: string;

  @f.enum(AuthProviderId)
  providerId: AuthProviderId;

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @f.optional()
  emailAlreadyInUse: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @f.optional()
  isAdmin: boolean = false;

  static create(data: UserDTO): User {
    return plainToClass(User, data);
  }

  toJSON(): UserDTO {
    return classToPlain(User, this);
  }
}
