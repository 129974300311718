import { pipe, UnaryFunction, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as R from 'ramda';

export function ifFalsy<T>(
  path?: string
): UnaryFunction<Observable<T>, Observable<T>> {
  return pipe(
    filter(
      (value) => !(path ? R.pathOr(undefined, path.split('.'), value) : value)
    )
  );
}
