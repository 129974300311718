import {
  createFeatureSelector,
  createSelector,
  createReducer,
  on
} from '@ngrx/store';

import * as MainNavActions from './actions';
import { MainNavMode } from '../types/main-nav-mode';

export interface State {
  opened: boolean;
  mode: MainNavMode;
}

export const initialState: State = {
  opened: false,
  mode: MainNavMode.Over
};

export function reducer(s: State, a: MainNavActions.ActionsUnion) {
  return createReducer(
    initialState,
    on(MainNavActions.open, (state) => ({ ...state, opened: true })),
    on(MainNavActions.close, (state) => ({ ...state, opened: false }))
  )(s, a);
}

export const getMainNavState = createFeatureSelector<State>('shellMainNav');

export const getOpened = createSelector(
  getMainNavState,
  (state) => state.opened
);

export const getMode = createSelector(getMainNavState, (state) => state.mode);
