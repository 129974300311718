import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { untilDestroy, UntilDestroy } from '@gdl/shared/browser/utils';

@UntilDestroy()
@Component({
  selector: 'gdl-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainNavComponent {
  @Input() showAdminNav = false;

  constructor(sidenav: MatSidenav, router: Router) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter(() => sidenav.opened),
        untilDestroy(this)
      )
      .subscribe(() => sidenav.close());
  }
}
