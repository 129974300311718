import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  copy(data) {
    const element = this.createFakeElement(data);

    this.copyFromElement(element);
    this.cleanup(element);
  }

  private cleanup(element: HTMLTextAreaElement) {
    document.body.removeChild(element);
  }

  private copyFromElement(element: HTMLTextAreaElement) {
    element.select();
    document.execCommand('copy');
  }

  private createFakeElement(data): HTMLTextAreaElement {
    const element = document.createElement('textarea');

    element.setAttribute('readonly', '');
    element.style.position = 'absolute';
    element.style.left = '-9999px';

    element.value = this.prepareData(data);

    document.body.appendChild(element);

    return element;
  }

  private prepareData(data) {
    if (typeof data === 'string') {
      return data;
    }

    return JSON.stringify(data);
  }
}
