import { Component, ChangeDetectionStrategy } from '@angular/core';

import { ProgressBarService } from '../../services/progress-bar.service';

@Component({
  selector: 'gdl-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  constructor(public readonly progressBar: ProgressBarService) {}
}
