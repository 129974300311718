import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { MessengerService } from './services/messenger.service';

@NgModule({
  imports: [MatSnackBarModule],
  providers: [MessengerService]
})
export class MessengerModule {}
