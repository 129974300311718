import { createAction, props, union } from '@ngrx/store';
import { User, AuthProviderId } from '@gdl/auth/common/models';

/**
 * @ngrxAction
 */
export const signIn = createAction(
  '[Auth] Sign In',
  props<{ providerId: AuthProviderId; params?: Record<string, any> }>()
);

/**
 * @ngrxAction
 */
export const signedIn = createAction(
  '[Auth] Signed In',
  props<{ user: User }>()
);

/**
 * @ngrxAction
 */
export const signInError = createAction(
  '[Auth] Sign In Error',
  props<{ error: any }>()
);

/**
 * @ngrxAction
 */
export const signOut = createAction('[Auth] Sign Out');

/**
 * @ngrxAction
 */
export const beforeSignOut = createAction('[Auth] Before Sign Out');

/**
 * @ngrxAction
 */
export const proceedSignOut = createAction('[Auth] Proceed Sign Out');

/**
 * @ngrxAction
 */
export const signOutError = createAction(
  '[Auth] Sign Out Error',
  props<{ error: any }>()
);

/**
 * @ngrxAction
 */
export const signedOut = createAction('[Auth] Signed Out');

/**
 * @ngrxAction
 */
export const linkAccount = createAction('[Auth] Link Account');

/**
 * @ngrxAction
 */
export const linkAccountSuccess = createAction('[Auth] Link Account Success');

/**
 * @ngrxAction
 */
export const linkAccountError = createAction(
  '[Auth] Link Account Error',
  props<{ error: any }>()
);

/**
 * @ngrxAction
 */
export const linkWithPopupError = createAction(
  '[Auth] Link With Popup Error',
  props<{ error: any }>()
);

/**
 * @ngrxAction
 */
export const linkWithCredential = createAction(
  '[Auth] Link With Credential',
  props<{ credential: any }>()
);

/**
 * @ngrxActionlinkWithCredential
 */
export const unlinkAccount = createAction('[Auth] Unlink Account');

/**
 * @ngrxAction
 */
export const unlinkAccountSuccess = createAction(
  '[Auth] Unlink Account Success'
);

/**
 * @ngrxAction
 */
export const unlinkAccountError = createAction(
  '[Auth] Unlink Account error',
  props<{ error: any }>()
);

const all = union({
  signIn,
  signedIn,
  signInError,
  signOut,
  beforeSignOut,
  proceedSignOut,
  signOutError,
  signedOut,
  linkAccount,
  linkAccountSuccess,
  linkAccountError,
  linkWithPopupError,
  linkWithCredential,
  unlinkAccount,
  unlinkAccountSuccess,
  unlinkAccountError
});

export type ActionsUnion = typeof all;
