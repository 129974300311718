import { Injectable } from '@angular/core';
import {
  Auth,
  AuthCredential,
  user,
  UserCredential,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  signInWithCredential,
  signOut,
  linkWithPopup,
  linkWithCredential,
  unlink,
  deleteUser,
  updateEmail,
  updateProfile,
  User as FirebaseUser
} from '@angular/fire/auth';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { AuthProviderId, User } from '@gdl/auth/common/models';
import { Observable, from, forkJoin } from 'rxjs';
import { filter, tap, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  readonly state$: Observable<User | null>;

  private stateChangesLocked = false;

  constructor(private auth: Auth, private functions: Functions) {
    this.state$ = user(this.auth).pipe(
      filter(() => !this.stateChangesLocked),
      switchMap(async (user) => {
        if (user?.uid) {
          const isAdmin = await this.isAdminFromClaims(user);

          return User.create({
            id: user.uid,
            name: user.displayName || '',
            email: user.email || '',
            photoURL: user.photoURL || '',
            providerId:
              user.providerData &&
              user.providerData[0] &&
              user.providerData[0].providerId === AuthProviderId.Google
                ? AuthProviderId.Google
                : AuthProviderId.Custom,
            isAdmin
          });
        } else {
          return null;
        }
      })
    );
  }

  signIn(providerId: AuthProviderId, params: Record<string, any> = {}) {
    let state: Promise<UserCredential>;

    this.lockStateChanges();

    switch (providerId) {
      case AuthProviderId.Google: {
        const provider = this.createGoogleAuthProvider();
        state = signInWithPopup(this.auth, provider);
        break;
      }

      case AuthProviderId.Custom: {
        state = signInWithCustomToken(this.auth, params['customToken']);
      }
    }

    return from(state).pipe(
      switchMap(() => this.getCurrentUser()),
      switchMap(async (user) => {
        let emailAlreadyInUse = false;

        if (
          !user.email &&
          params['additionalParams'] &&
          params['additionalParams.email']
        ) {
          try {
            await updateEmail(user, params['additionalParams.email']);
          } catch (error: any) {
            if (error.code && error.code === 'auth/email-already-in-use') {
              emailAlreadyInUse = true;
            } else {
              console.warn(error);
            }
          }
        }

        if (!params['email'] && !params['name']) {
          return { user, emailAlreadyInUse };
        }

        if (params['name'] && user.displayName !== params['name']) {
          await updateProfile(user, { displayName: params['name'] });
        }
        if (params['email'] && user.email !== params['email']) {
          await updateEmail(user, params['email']);
        }

        return { user, emailAlreadyInUse };
      }),
      switchMap(async ({ user, emailAlreadyInUse }) => {
        const isAdmin = await this.isAdminFromClaims(user);

        return User.create({
          id: user.uid,
          name: user.displayName || '',
          email: user.email || '',
          photoURL: user.photoURL || '',
          providerId,
          emailAlreadyInUse,
          isAdmin
        });
      }),
      tap(() => this.unlockStateChanges())
    );
  }

  signOut() {
    this.lockStateChanges();

    return from(signOut(this.auth)).pipe(tap(() => this.unlockStateChanges()));
  }

  linkWithPopup() {
    return this.getCurrentUser().pipe(
      switchMap((currentUser) => {
        const provider = this.createGoogleAuthProvider();
        return linkWithPopup(currentUser, provider);
      })
    );
  }

  linkWithCredential(credential: AuthCredential) {
    return forkJoin([
      from(signInWithCredential(this.auth, credential)),
      this.getCurrentUser()
    ]).pipe(
      switchMap(([userCredential, currentUser]) => {
        const googleUser = userCredential.user;
        const gooogleUserId = googleUser.uid;
        const currentUserId = currentUser.uid;

        return this.mergeUserData(currentUserId, gooogleUserId).pipe(
          switchMap(() => deleteUser(googleUser)),
          switchMap(() => linkWithCredential(currentUser, credential))
        );
      })
    );
  }

  unlinkUser() {
    const provider = this.createGoogleAuthProvider();
    return this.getCurrentUser().pipe(
      switchMap((user) => unlink(user, provider.providerId))
    );
  }

  private async isAdminFromClaims(user: FirebaseUser) {
    const claims = await this.getClaims(user);
    return !!claims['admin'];
  }

  private async getClaims(user: FirebaseUser) {
    const idTokenResult = await user.getIdTokenResult();
    return idTokenResult.claims;
  }

  private getCurrentUser() {
    return user(this.auth).pipe(
      take(1),
      filter((user) => !!user)
    ) as Observable<FirebaseUser>;
  }

  private mergeUserData(customProviderUserId: string, googleUserId: string) {
    return httpsCallableData(
      this.functions,
      'guidelines-linkAccount'
    )({
      customProviderUserId,
      googleUserId
    });
  }

  private lockStateChanges() {
    this.stateChangesLocked = true;
  }

  private unlockStateChanges() {
    this.stateChangesLocked = false;
  }

  private createGoogleAuthProvider() {
    return new GoogleAuthProvider();
  }
}
