import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { AuthProviderId } from '@gdl/auth/common/models';
import { AuthService } from '@gdl/auth/browser/core';
import { map, Observable } from 'rxjs';

import { AppbarService } from '../../services/appbar.service';

@Component({
  selector: 'gdl-appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppbarComponent {
  @Output() readonly menu = new EventEmitter();
  @Output() readonly notifications = new EventEmitter();

  @Input() set notificationsCount(v) {
    this._notificationsCount = v || null;
  }

  get notificationsCount() {
    return this._notificationsCount;
  }

  readonly AuthProviderId = AuthProviderId;

  accountLinkingAvailable$: Observable<boolean>;

  private _notificationsCount: number | null = null;

  constructor(
    public readonly appbar: AppbarService,
    private authService: AuthService
  ) {
    this.accountLinkingAvailable$ = appbar.providerId$.pipe(
      map(
        (id) => id === AuthProviderId.Custom && this.authService.oktaIsEnabled
      )
    );
  }
}
