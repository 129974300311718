/**
 * Notifications
 */
export const getNotificationsCollectionPath = () => 'notifications';

export const getUserNotificationsCollectionPath = (userId: string) =>
  `${getNotificationsCollectionPath()}/${userId}/notifications`;

export const getUserNotificationDocumentPath = (
  userId: string,
  notificationId: string
) => `${getUserNotificationsCollectionPath(userId)}/${notificationId}`;

/**
 * FCM Tokens
 */
export const getUsersCollectionPath = () => 'users';
