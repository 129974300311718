import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import {
  ScreenTrackingService,
  UserTrackingService,
  provideAnalytics,
  getAnalytics
} from '@angular/fire/analytics';
import {
  providePerformance,
  getPerformance,
  Performance
} from '@angular/fire/performance';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthCoreModule, AUTH_OPTIONS } from '@gdl/auth/browser/core';
import { ENVIRONMENT } from '@gdl/harvester/browser/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ShellAppbarModule } from '@gdl/shared/browser/shell/appbar';
import { ShellMainNavModule } from '@gdl/harvester/browser/shell/main-nav';
import { ShellProgressBarModule } from '@gdl/shared/browser/shell/progress-bar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { APP_VERSION, APP_NAME } from '@gdl/shared/browser/utils';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NotificationsUiModule } from '@gdl/notifications/browser/ui';
import { AuthOktaModule, OKTA_OPTIONS } from '@gdl/auth/browser/okta';
import { ErrorReportingModule } from '@gdl/shared/browser/error-reporting';
import { RouterModule as GdlRouterModule } from '@gdl/router/browser';
import { GDL_APPS_OPTIONS } from '@gdl/gdl/browser/export';
// import { FcmModule } from '@gdl/auth/browser/fcm';
import { FirestoreModule } from '@gdl/firestore/browser/core';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppEffects } from './app.effects';
import {
  MATERIAL_DEFAULTS_PROVIDERS,
  AUTH_PROVIDERS,
  AUTOTRAVERSAL_PROVIDERS
} from './app.providers';
import { AppErrorHandler } from './error-handler';
import { ROUTES } from './app.routes';

const DEV_TOOLS = [];

if (!environment.production) {
  DEV_TOOLS.push(
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: false,
      features: {
        pause: false,
        lock: true,
        persist: true
      },
      connectInZone: true
    })
  );
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AuthCoreModule,
    AuthOktaModule,
    RouterModule.forRoot(ROUTES, { initialNavigation: 'enabledBlocking' }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    provideFunctions(() => {
      const app = getApp();
      return getFunctions(app, environment.firebase.region);
    }),
    provideStorage(() => getStorage()),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }
    ),
    EffectsModule.forRoot([AppEffects]),
    BrowserAnimationsModule,
    MatSidenavModule,
    AuthCoreModule,
    ShellAppbarModule,
    ShellMainNavModule,
    ShellProgressBarModule,
    FlexLayoutModule,
    // FcmModule,
    FirestoreModule,
    DEV_TOOLS,
    GdlRouterModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production
    }),
    ErrorReportingModule.forRoot({
      apiKey: environment.stackdriver.errorsReporter.key,
      disabled: environment.stackdriver.errorsReporter.disabled,
      bypass: environment.stackdriver.errorsReporter.bypass,
      projectId: environment.projectId,
      version: environment.version
    }),
    NotificationsUiModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: AUTH_OPTIONS, useValue: environment.auth },
    { provide: GDL_APPS_OPTIONS, useValue: environment.gdlApps },
    { provide: APP_NAME, useValue: 'Harvester' },
    {
      provide: OKTA_OPTIONS,
      useValue: environment.okta
    },
    MATERIAL_DEFAULTS_PROVIDERS,
    AUTH_PROVIDERS,
    AUTOTRAVERSAL_PROVIDERS,
    ScreenTrackingService,
    UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private performance: Performance) {}
}
