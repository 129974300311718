import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';

import { MessengerService } from '@gdl/shared/browser/messenger';
import { ClipboardService } from '@gdl/shared/browser/clipboard';

import * as ExportActions from '../actions/export.actions';
import { ExportService } from '../../services/export.service';

@Injectable()
export class ExportEffects {
  gdlExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExportActions.gdlExport),
      mergeMap(({ data, product }) =>
        this.exportService.export(data, product).pipe(
          map(() => ExportActions.gdlExported({ product })),
          catchError((error) =>
            of(ExportActions.gdlExportError({ error, product }))
          )
        )
      )
    )
  );

  exported$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExportActions.gdlExported),
        tap(({ product }) => {
          this.messengerService.info(
            `Guideline has been exported to ${product}`
          );
        })
      ),
    {
      dispatch: false
    }
  );

  exportError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExportActions.gdlExportError),
        tap(({ error, product }) => {
          console.warn(error);

          const errorMessage =
            (error.error && error.error.message) || error.message || '';

          this.messengerService
            .info(`Failed to export guideline to ${product}. ${errorMessage}`, {
              duration: 60000,
              actionLabel: 'Copy to clipboard'
            })
            .onAction()
            .subscribe(() => {
              this.clipboard.copy(errorMessage);
            });
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private exportService: ExportService,
    private messengerService: MessengerService,
    private readonly clipboard: ClipboardService
  ) {}
}
