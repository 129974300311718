import { EventEmitter, Injectable, Inject } from '@angular/core';
import { skip } from 'rxjs/operators';

import { AuthProviderId } from '@gdl/auth/common/models';

import { AuthFacade } from '../state';
import { AuthRoutingService } from './auth-routing.service';
import { AuthOptions } from '../models/auth-options';
import { AUTH_OPTIONS } from '../tokens';

@Injectable()
export class AuthService {
  readonly isAuthenticated$ = this.authFacade.isAuthenticated$;
  readonly isAdmin$ = this.authFacade.isAdmin$;
  readonly processing$ = this.authFacade.processing$;
  readonly user$ = this.authFacade.user$;
  readonly beforeSignOut$ = new EventEmitter();
  readonly reSignedIn$ = new EventEmitter();
  oktaIsEnabled: boolean;

  constructor(
    private authFacade: AuthFacade,
    private authRoutingService: AuthRoutingService,
    @Inject(AUTH_OPTIONS) options: AuthOptions
  ) {
    this.oktaIsEnabled = options.oktaIsEnabled;
  }

  /**
   * Sign in user with google
   */
  signInWithGoogle() {
    this.signIn(AuthProviderId.Google);
  }

  /**
   * Sign in user with custom token
   */
  signInWithCustomToken(
    customToken: string,
    additionalParams?: Record<string, any>
  ) {
    this.signIn(AuthProviderId.Custom, { customToken, additionalParams });
  }

  /**
   * Sign in user with specified provider
   *
   * @param providerId - Id of provider which should be used for sign in
   * @param params - Additional parameters
   */
  signIn(providerId: AuthProviderId, params?: Record<string, any>) {
    this.authFacade.signIn(providerId, params);
  }

  /**
   * Sign out user
   */
  signOut() {
    this.beforeSignOut$.emit();
    this.authFacade.signOut();
  }

  /**
   * Link Account
   */
  linkAccount() {
    this.authFacade.linkAccount();
  }

  /**
   * Unlink Account
   */
  unlinkAccount() {
    this.authFacade.unlinkAccount();
  }

  init() {
    this.isAuthenticated$.pipe(skip(1)).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.reSignedIn$.emit();
        this.authRoutingService.redirectToUserHome();
      } else {
        this.authRoutingService.redirectToAnonymousHome();
      }
    });
  }
}
