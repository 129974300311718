import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthEffects } from './auth.effects';
import { AuthFacade } from './auth.facade';
import { reducer } from './auth.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('auth', reducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [AuthFacade]
})
export class AuthStateModule {}
