import { NgModule } from '@angular/core';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { RouterFacade } from './router.facade';

@NgModule({
  imports: [
    StoreModule.forFeature('router', reducer),
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [RouterFacade]
})
export class RouterModule {}
