import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, NotificationsState } from './reducers';
import { createNotificationsSelectors } from './selectors/notifications.selectors';

/**
 * Feature
 */
const getFeatureState = createFeatureSelector<State>('notifications');

/**
 * Notifications
 */
const getNotificationsState = createSelector(
  getFeatureState,
  (state) => state.notifications
);

export const NotificationsQuery = createNotificationsSelectors(
  getNotificationsState
);
