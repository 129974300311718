import { User } from '@gdl/auth/common/models';
import {
  createFeatureSelector,
  createSelector,
  createReducer,
  on,
  Action
} from '@ngrx/store';

import * as AuthActions from './auth.actions';

export interface AuthState {
  processing: boolean;
  checked: boolean;
  user: User | null;
  isLinkingAccount: boolean;
}

export interface State {
  auth: AuthState;
}

export const initialState: AuthState = {
  processing: false,
  checked: false,
  user: null,
  isLinkingAccount: false
};

const reducer_ = createReducer(
  initialState,
  on(AuthActions.signIn, AuthActions.signOut, (s) => ({
    ...s,
    processing: true
  })),
  on(AuthActions.signInError, AuthActions.signOutError, (s) => ({
    ...s,
    processing: false
  })),
  on(AuthActions.signedIn, (s, { user }) => ({
    ...s,
    processing: false,
    checked: true,
    user
  })),
  on(AuthActions.signedOut, (s) => ({
    ...s,
    processing: false,
    checked: true,
    user: null
  })),
  on(AuthActions.linkAccount, (s) => ({
    ...s,
    isLinkingAccount: true
  })),
  on(AuthActions.linkAccountSuccess, AuthActions.linkAccountError, (s) => ({
    ...s,
    isLinkingAccount: false
  }))
);

export function reducer(state: AuthState, action: Action) {
  return reducer_(state, action);
}

// Selectors

export const getAuthState = createFeatureSelector<AuthState>('auth');

export const getProcessing = createSelector(
  getAuthState,
  (state) => state.processing
);

export const getChecked = createSelector(
  getAuthState,
  (state) => state.checked
);

export const getUser = createSelector(getAuthState, (state) => state.user);

export const getIsAuthenticated = createSelector(getUser, (user) => {
  return !!user;
});

export const getUserId = createSelector(getUser, (user) =>
  user ? user.id : null
);

export const getIsLinkingAccount = createSelector(
  getAuthState,
  (state) => state.isLinkingAccount
);

export const getIsAdmin = createSelector(
  getUser,
  (user) => user?.isAdmin ?? false
);
