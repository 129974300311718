import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as ExportActions from '../actions/export.actions';
import { ExportState } from '../reducers';
import { ExportQuery } from '../selector';
import { NavifyProduct } from '../../models';

@Injectable({ providedIn: 'root' })
export class ExportFacade {
  readonly isExporting$ = this.store.pipe(select(ExportQuery.getExporting));

  constructor(private store: Store<ExportState>) {}

  export(
    guidelineId: string,
    data: Record<string, any>,
    product: NavifyProduct
  ) {
    this.store.dispatch(
      ExportActions.gdlExport({ guidelineId, data, product })
    );
  }
}
