import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  map,
  switchMap,
  catchError,
  mapTo,
  tap,
  withLatestFrom,
  filter
} from 'rxjs/operators';
import { FirebaseAuthService } from '@gdl/auth/browser/firebase';
// import { FcmService } from '@gdl/auth/browser/fcm';
import { MessengerService } from '@gdl/shared/browser/messenger';
import { AuthProviderId } from '@gdl/auth/common/models';

import * as AuthActions from './auth.actions';
import { State } from './auth.reducer';
import { AuthRoutingService } from '../services/auth-routing.service';
import { AuthService } from '../services/auth.service';

const ALLOWED_ERROR_CODES = [
  'auth/credential-already-in-use',
  'auth/email-already-in-use'
];

@Injectable()
export class AuthEffects {
  /**
   * @ngrxEffect
   */
  authState$ = createEffect(() =>
    this.firebaseAuthService.state$.pipe(
      map((user) =>
        user ? AuthActions.signedIn({ user }) : AuthActions.signedOut()
      )
    )
  );

  /**
   * @ngrxEffect
   */
  isNotAuthenticated$ = createEffect(() =>
    this.authService.isAuthenticated$.pipe(
      filter((isAuthenticated) => !isAuthenticated),
      mapTo(AuthActions.signOut())
    )
  );

  /**
   * @ngrxEffect
   */
  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signIn),
      switchMap(({ providerId, params }) => {
        return this.firebaseAuthService.signIn(providerId, params).pipe(
          map((user) => AuthActions.signedIn({ user })),
          catchError((error) => of(AuthActions.signInError({ error })))
        );
      })
    )
  );

  /**
   * @ngrxEffect
   */
  // signedIn$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(AuthActions.signedIn),
  //       tap(({ user }) => {
  //         this.fcmService.requestPermission(user.id);
  //       })
  //     ),
  //   {
  //     dispatch: false
  //   }
  // );

  /**
   * @ngrxEffect
   */
  signedInWithExitingEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signedIn),
        filter(
          ({ user }) =>
            user.emailAlreadyInUse && user.providerId !== AuthProviderId.Google
        ),
        tap(() => {
          this.messengerService.info(
            'Email already in use, please link this account with your Google account'
          );
        })
      ),
    {
      dispatch: false
    }
  );

  /**
   * @ngrxEffect
   */
  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signOut),
      map(() => AuthActions.beforeSignOut())
    )
  );

  /**
   * @ngrxEffect
   */
  beforeSignOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.beforeSignOut),
      // withLatestFrom(this.store.select(getUserId)),
      // switchMap(([_, userId]) =>
      //   this.fcmService
      //     .deleteToken(userId)
      //     .pipe(mapTo(AuthActions.proceedSignOut()))
      // ),
      mapTo(AuthActions.proceedSignOut())
    )
  );

  /**
   * @ngrxEffect
   */
  proceedSignOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.proceedSignOut),
      switchMap(() =>
        this.firebaseAuthService.signOut().pipe(
          mapTo(AuthActions.signedOut()),
          catchError((error) => of(AuthActions.signOutError({ error })))
        )
      )
    )
  );

  /**
   * @ngrxEffect
   */
  signedOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signedOut),
      map(() => ({ type: '@SignedOut' }))
    )
  );

  /**
   * @ngrxEffect
   */
  linkAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.linkAccount),
      switchMap(() =>
        this.firebaseAuthService.linkWithPopup().pipe(
          mapTo(AuthActions.linkAccountSuccess()),
          catchError((error) => of(AuthActions.linkWithPopupError({ error })))
        )
      )
    )
  );

  /**
   * @ngrxEffect
   */
  linkAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.linkAccountSuccess),
        tap(() => {
          this.messengerService.info('Account has been linked');
          this.authRoutingService.redirectToUserHome();
        })
      ),
    {
      dispatch: false
    }
  );

  /**
   * @ngrxEffect
   */
  credentialAlreadyInUse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.linkWithPopupError),
      filter(
        ({ error }) => error.code && ALLOWED_ERROR_CODES.includes(error.code)
      ),
      map(({ error }) =>
        AuthActions.linkWithCredential({ credential: error.credential })
      )
    )
  );

  /**
   * @ngrxEffect
   */
  linkWithPopupError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.linkWithPopupError),
      filter(
        ({ error }) => !error.code || !ALLOWED_ERROR_CODES.includes(error.code)
      ),
      map(({ error }) => AuthActions.linkAccountError({ error }))
    )
  );

  /**
   * @ngrxEffect
   */
  linkWithCredential$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.linkWithCredential),
      switchMap(({ credential }) =>
        this.firebaseAuthService.linkWithCredential(credential)
      ),
      mapTo(AuthActions.linkAccountSuccess()),
      catchError((error) => of(AuthActions.linkAccountError({ error })))
    )
  );

  /**
   * @ngrxEffect
   */
  linkAccountError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.linkAccountError),
        tap(({ error }) => {
          console.warn(error);
          this.messengerService.error(error.message || 'Something went wrong');
          this.authRoutingService.redirectToUserHome();
        })
      ),
    {
      dispatch: false
    }
  );

  /**
   * @ngrxEffect
   */
  unlinkAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.unlinkAccount),
      switchMap(() =>
        this.firebaseAuthService.unlinkUser().pipe(
          mapTo(AuthActions.unlinkAccountSuccess()),
          catchError((error) => of(AuthActions.unlinkAccountError({ error })))
        )
      )
    )
  );

  /**
   * @ngrxEffect
   */
  unlinkAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.unlinkAccountSuccess),
        tap(() => {
          this.messengerService.info('Account has been unlinked');
          this.authRoutingService.redirectToUserHome();
        })
      ),
    {
      dispatch: false
    }
  );

  /**
   * @ngrxEffect
   */
  unlinkAccountError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.unlinkAccountError),
        tap(({ error }) => {
          console.warn(error);
          this.messengerService.error(error.message || 'Something went wrong');
          this.authRoutingService.redirectToUserHome();
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private firebaseAuthService: FirebaseAuthService,
    private authRoutingService: AuthRoutingService,
    private store: Store<State>,
    // private fcmService: FcmService,
    private messengerService: MessengerService,
    private authService: AuthService
  ) {}
}
