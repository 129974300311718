import { AccessControlList } from '../access-control-list';
import { Role } from '../role';

export const createACL = (
  userRolesMap: Record<string, Role> = {}
): AccessControlList => {
  return AccessControlList.create({
    users: Object.keys(userRolesMap),
    roles: { ...userRolesMap }
  });
};

export const getACL = (document: {
  acl?: AccessControlList;
}): AccessControlList => (document && document.acl ? document.acl : null);

export const hasACL = (document: { acl?: AccessControlList }): boolean =>
  !!getACL(document);

export const addACL = (document: {
  acl?: AccessControlList;
}): AccessControlList => {
  const acl = createACL();
  document.acl = acl;

  return document.acl;
};

export const getOrCreateACL = (document: {
  acl?: AccessControlList;
}): AccessControlList => {
  if (!hasACL(document)) {
    addACL(document);
  }

  return getACL(document);
};

export const isUserOnACL = (
  document: { acl?: AccessControlList },
  userId: string
): boolean => {
  if (!hasACL(document)) {
    return false;
  }
  const acl = getACL(document);

  return acl.users.indexOf(userId) > -1 && !!acl.roles[userId];
};

export const addUserToACL = (
  document: { acl?: AccessControlList },
  userId: string,
  role: Role
): { acl?: AccessControlList } => {
  if (!isUserOnACL(document, userId)) {
    const acl = getOrCreateACL(document);
    acl.roles[userId] = role;
    acl.users.push(userId);
  }

  return document;
};

export const removeUserFromACL = (
  document: { acl?: AccessControlList },
  userId: string
): { acl?: AccessControlList } => {
  if (hasACL(document) && isUserOnACL(document, userId)) {
    const acl = getACL(document);
    acl.users = acl.users.filter((i) => i !== userId);
    delete acl.roles[userId];
  }

  return document;
};

export const roleAllowed = (role: Role, allowedRoles: Role[]) => {
  return allowedRoles.indexOf(role) > -1;
};

export const getUserRole = (
  userId: string | null,
  document: { acl?: AccessControlList }
): Role => {
  if (!userId || !isUserOnACL(document, userId)) {
    return null;
  }

  const acl = getACL(document);

  return acl.roles[userId];
};

export const hasAccess = (
  userId: string,
  document: { acl?: AccessControlList },
  allowedRoles: Role[]
) => {
  const userRole = getUserRole(userId, document);
  const access = userRole ? roleAllowed(userRole, allowedRoles) : false;

  return access;
};
