import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { map } from 'rxjs/operators';

import { AuthRoutingService } from '../services/auth-routing.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthAdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private routing: AuthRoutingService
  ) {}

  canActivate() {
    return this.authService.isAdmin$.pipe(
      map((isAuthenticated) =>
        !isAuthenticated ? this.routing.getAnonymousHome() : true
      )
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
