import { createSelector } from '@ngrx/store';
import {
  Notification,
  NotificationStatus
} from '@gdl/notifications/common/models';
import * as fromNotifications from '../reducers/notifications.reducer';

export function createNotificationsSelectors<V>(
  selectState: (state: V) => fromNotifications.State
) {
  const {
    selectAll: getAll,
    selectEntities: getEntities,
    selectIds: getIds
  } = fromNotifications.adapter.getSelectors(selectState);

  const getByIdFromEntities = (
    entities: Record<string, Notification>,
    notificationId: string
  ) => (entities && notificationId ? entities[notificationId] || null : null);

  const getById = createSelector(getEntities, getByIdFromEntities);

  const getSync = createSelector(selectState, (state) => state.sync);

  const getLoading = createSelector(selectState, (state) => state.loading);

  const getUnreaded = createSelector(getAll, (entities) =>
    entities.filter((entity) => entity.status === NotificationStatus.Unreaded)
  );

  const getAllCount = createSelector(getIds, (ids) => ids.length);

  const getUnreadedCount = createSelector(
    getUnreaded,
    (unreaded) => unreaded.length
  );

  return {
    getAll,
    getAllCount,
    getUnreaded,
    getUnreadedCount,
    getEntities,
    getById,
    getSync,
    getLoading
  };
}
