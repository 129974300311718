import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OktaAuthService } from '@gdl/auth/browser/okta';
import { filter, mergeMap } from 'rxjs/operators';

import { GDL_APPS_OPTIONS } from '../tokens';
import { GdlAppsOptions, NavifyProduct } from '../models/gdl-app-options';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(
    private http: HttpClient,
    private oktaAuthService: OktaAuthService,
    @Inject(GDL_APPS_OPTIONS) private options: GdlAppsOptions
  ) {}

  export(data: Record<string, any>, product: NavifyProduct) {
    const options = this.options[product];

    return this.oktaAuthService.getOktaToken().pipe(
      filter(Boolean),
      mergeMap((token) =>
        this.http.post<any>(`${options.url}${options.api.guidelines}`, data, {
          headers: {
            authorization: `Bearer ${token}`,
            'X-Navify-Tenant': options.tenant
          }
        })
      )
    );
  }
}
