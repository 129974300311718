import { Action, combineReducers } from '@ngrx/store';

import * as fromExport from './reducers/export.reducer';

export interface State {
  export: fromExport.State;
}

export interface ExportState {
  gdlExport: State;
}

export function reducers(state: State, action: Action) {
  return combineReducers({
    export: fromExport.reducer
  })(state, action);
}
