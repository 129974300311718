import * as R from 'ramda';

let counter = {};
let timer = {};

let disabled = true;

export function Count() {
  return function (
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const className = target.constructor
      ? target.constructor.name
      : target.name;
    const methodName = propertyKey;
    const oldMethod = descriptor.value;

    descriptor.value = function (...args) {
      if (!disabled) {
        const key = `${className}.${methodName}`;
        counter[key] = counter[key] || 1;
        counter[key]++;

        const start = Date.now();
        const result = oldMethod.apply(this, args);
        const stop = Date.now();

        timer[key] = timer[key] || [];
        timer[key].push({ start, stop });

        return result;
      }

      return oldMethod.apply(this, args);
    };
  };
}

Count.disable = () => (disabled = true);
Count.enable = () => (disabled = false);

Count.reset = () => {
  counter = {};
  timer = {};
};

Count.show = () => {
  for (const k of Object.keys(counter)) {
    console.log(
      `${k}, ${counter[k]} times, avg ${R.mean(timer[k])}ms, median ${R.median(
        timer[k]
      )} ms`
    );
  }
};

Count.dump = () => {
  const dump = [];

  for (const k of Object.keys(counter)) {
    const timeData = timer[k].reduce(
      (aggr, item) => {
        aggr.count++;
        aggr.total = aggr.total + (item.stop - item.start);

        return aggr;
      },
      { name: k, total: 0, count: 0, avg: 0 }
    );

    timeData.avg = R.mean(timer[k].map((t) => t.stop - t.start));
    timeData.med = R.median(timer[k].map((t) => t.stop - t.start));

    dump.push(timeData);
  }

  return dump;
};
