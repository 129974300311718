import { Injectable, Inject } from '@angular/core';
import {
  filter,
  skipUntil,
  map,
  distinctUntilChanged,
  switchMapTo
} from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { AuthProviderId } from '@gdl/auth/common/models';
import { Store, select } from '@ngrx/store';
import { OktaAuthService } from '@gdl/auth/browser/okta';
import { AUTH_OPTIONS } from '../tokens';
import { AuthOptions } from '../models/auth-options';

import {
  State,
  getChecked,
  getIsAuthenticated,
  getUser,
  getProcessing,
  getIsAdmin
} from './auth.reducer';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthFacade {
  private checked$ = this.store.pipe(select(getChecked), filter(Boolean));
  private readonly isFirebaseAuthenticated$ = this.store.pipe(
    skipUntil(this.checked$),
    select(getIsAuthenticated)
  );

  readonly isAuthenticated$: Observable<boolean>;
  readonly isAdmin$: Observable<boolean>;
  readonly processing$ = this.store.pipe(select(getProcessing));
  readonly user$ = this.store.pipe(skipUntil(this.checked$), select(getUser));

  constructor(
    private store: Store<State>,
    private oktaAuthService: OktaAuthService,
    @Inject(AUTH_OPTIONS) private options: AuthOptions
  ) {
    this.isAuthenticated$ = options.oktaIsEnabled
      ? combineLatest([
          this.isFirebaseAuthenticated$,
          this.oktaAuthService.isAuthenticated$
        ]).pipe(
          map(
            ([isFirebaseAuthenticated, isOktaAuthenticated]) =>
              isFirebaseAuthenticated && isOktaAuthenticated
          ),
          distinctUntilChanged()
        )
      : this.isFirebaseAuthenticated$;

    this.isAdmin$ = this.isAuthenticated$.pipe(
      switchMapTo(this.store.select(getIsAdmin))
    );
  }

  /**
   * Sign in user with google
   */
  signInWithGoogle() {
    this.signIn(AuthProviderId.Google);
  }

  signInWithCustomToken(
    customToken: string,
    additionalParams?: Record<string, any>
  ) {
    this.signIn(AuthProviderId.Custom, { customToken, additionalParams });
  }

  /**
   * Sign in user with specified provider
   *
   * @param providerId - Id of provider which should be used for sign in
   * @param params - Additional parameters
   */
  signIn(providerId: AuthProviderId, params?: Record<string, any>) {
    this.store.dispatch(AuthActions.signIn({ providerId, params }));
  }

  /**
   * Sign out user
   */
  signOut() {
    this.store.dispatch(AuthActions.signOut());
  }

  /**
   * Link Account
   */
  linkAccount() {
    this.store.dispatch(AuthActions.linkAccount());
  }

  /**
   * Unlink Account
   */
  unlinkAccount() {
    this.store.dispatch(AuthActions.unlinkAccount());
  }
}
