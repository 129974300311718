import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  AfterViewInit,
  Inject
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { OktaOptions } from '../../models/okta-options';
import { OKTA_OPTIONS } from '../../tokens';
import { OktaAuthService } from '../../services/okta-auth.service';

@Component({
  selector: 'gdl-okta-signin',
  templateUrl: './okta-signin.component.html',
  styleUrls: ['./okta-signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OktaSigninComponent implements OnInit, AfterViewInit {
  private oktaSignIn: OktaSignIn;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(OKTA_OPTIONS) private options: OktaOptions,
    private dialogRef: MatDialogRef<OktaSigninComponent>,
    private oktaAuthService: OktaAuthService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    const containerId = `okta-container-${Date.now()}`;
    const container = this.document.createElement('div');
    container.setAttribute('id', containerId);
    this.elementRef.nativeElement.append(container);

    this.oktaSignIn = new OktaSignIn({
      baseUrl: this.options.orgUrl,
      authParams: {
        display: 'page'
      },
      clientId: this.options.clientId,
      redirectUri: this.document.defaultView.location.origin,
      getAccessToken: true,
      getIdToken: true,
      el: `#${containerId}`,
      scope: 'openid profile email',
      logo: '/assets/icons/app/icon-72x72.png',
      i18n: {
        en: {
          'primaryauth.title': 'Sign in to Harvester'
        }
      }
    });

    this.show();
  }

  private async show() {
    const response = await this.oktaSignIn
      .showSignInToGetTokens()
      .then((tokens: any) => {
        this.oktaAuthService.setTokens(tokens);
        this.oktaSignIn.remove();

        return tokens;
      });

    this.dialogRef.close(response);
  }
}
