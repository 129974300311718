import { createAction, union, props } from '@ngrx/store';
import { AuthProviderId } from '@gdl/auth/common/models';

export const show = createAction('[Shell Appbar] Show');

export const hide = createAction('[Shell Appbar] Hide');

export const setTitle = createAction(
  '[Shell Appbar] Set Title',
  props<{ title: string; subtitle?: string }>()
);

export const setUserPhotoURL = createAction(
  '[Shell Appbar] Set User Photo URL',
  props<{ url: string }>()
);

export const setProviderId = createAction(
  '[Shell Appbar] Set Provider ID',
  props<{ providerId: AuthProviderId }>()
);

const all = union({
  show,
  hide,
  setTitle,
  setUserPhotoURL,
  setProviderId
});

export type ActionsUnion = typeof all;
