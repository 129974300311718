import { createReducer, on } from '@ngrx/store';
import * as ExportActions from '../actions/export.actions';
import { NavifyProduct } from '../../models';

export interface State {
  exporting: Record<NavifyProduct, boolean>;
}

export const initialState: State = {
  exporting: {
    [NavifyProduct.NTB]: false,
    [NavifyProduct.NCH]: false
  }
};

export const reducer = createReducer(
  initialState,
  on(ExportActions.gdlExport, (state, { product }) => ({
    exporting: { ...state.exporting, [product]: true }
  })),
  on(ExportActions.gdlExported, (state, { product }) => ({
    exporting: { ...state.exporting, [product]: false }
  })),
  on(ExportActions.gdlExportError, (state, { product }) => ({
    exporting: { ...state.exporting, [product]: false }
  }))
);
